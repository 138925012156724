.splide {
  outline: none;
  position: relative;
  visibility: hidden;

  &.is-initialized,
  &.is-rendered {
    visibility: visible;
  }
}
