@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.splide--draggable > .splide__slider > .splide__track, .splide--draggable > .splide__track {
  -webkit-user-select: none;
  user-select: none;
}

.splide--fade > .splide__slider > .splide__track > .splide__list, .splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide, .splide--fade > .splide__track > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
  position: relative;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb.is-active > .splide__slider > .splide__track > .splide__list, .splide--ttb.is-active > .splide__track > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  outline: none;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  contain: strict;
  height: 20px;
  width: 20px;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

.splide__arrow {
  opacity: 0;
  fill: #fff;
  filter: drop-shadow(0 0 6px #000);
  z-index: 1;
  background-color: #0000;
  border: none;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow--prev {
  left: 5px;
  transform: translateY(-50%)rotate(180deg);
}

.splide__arrow--next {
  right: 5px;
}

.splide:hover .splide__arrow, .splide:focus-within .splide__arrow {
  opacity: 1;
}

.splide.last-posts .splide__arrow {
  width: 60px;
  height: 60px;
  opacity: 1;
  z-index: 1;
  filter: none;
  background-color: #3a3286;
  border: none;
  border-radius: 0 20px 0 40px;
  position: absolute;
  top: 110%;
  transform: translateY(-50%);
}

.splide.last-posts .splide__arrow svg {
  fill: #d8b56d;
  height: 20px;
}

.splide__pagination {
  pointer-events: none;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 1em;
  display: flex;
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  height: 10px;
  width: 10px;
  opacity: .8;
  background: #7b7b7b;
  border: 0;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: background-color .2s linear, transform .2s linear;
  display: inline-block;
  position: relative;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
  background: #fff;
}

.splide__pagination__page.is-active {
  z-index: 1;
  background: #fff;
  transform: scale(1.4);
}

@media (width >= 992px) {
  .splide.last-posts .splide__arrow {
    top: calc(50% + 25px);
    transform: translateY(-50%);
  }

  .splide.last-posts .splide__arrow--prev {
    display: none;
  }

  .splide.last-posts .splide__arrow--next {
    right: -75px;
  }
}

@media (width <= 991.98px) {
  .splide.last-posts .splide__arrow--prev, .splide.last-posts .splide__arrow--next {
    top: calc(100% + 30px);
    left: calc(50% - 30px);
    transform: translateX(75%);
  }

  .splide.last-posts .splide__arrow--prev {
    border-radius: 20px 0 40px;
    transform: translateX(-75%);
  }

  .splide.last-posts .splide__arrow--prev svg {
    transform: rotate(-180deg);
  }
}

/*# sourceMappingURL=splide.css.map */
