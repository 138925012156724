// Bootstrap
@import "./partials/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Custom
@import "partials/mixins";

// Core styles
@import "partials/splide";

// Custom styles

.splide__arrow {
  opacity: 0;
  transition: opacity 0.3s;
  background-color: transparent;
  border: none;
  fill: #fff;
  filter: drop-shadow(0 0 6px #000);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &--prev {
    left: 5px;
    transform: translateY(-50%) rotate(180deg);
  }

  &--next {
    right: 5px;
  }
}

// Homepage slider - show arrows only on hover/focus
.splide {
  &:hover,
  &:focus-within {
    .splide__arrow {
      opacity: 1;
    }
  }
}

// 'Last posts' slider - custom arrows
.splide.last-posts {
  .splide__arrow {
    position: absolute;
    top: 110%;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    opacity: 1;
    z-index: 1;

    background-color: $dark;
    border: none;
    border-radius: 0 20px 0 40px;

    filter: none;

    svg {
      fill: $secondary;
      height: 20px;
    }

    @include media-breakpoint-down(lg) {
      &--prev,
      &--next {
        top: calc(100% + 30px);
        left: calc(50% - 30px);
        transform: translateX(75%);
      }

      &--prev {
        transform: translateX(-75%);
        border-radius: 20px 0 40px 0;
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      top: calc(50% + 25px);
      transform: translateY(-50%);

      &--prev {
        display: none;
      }

      &--next {
        right: -75px;
      }
    }
  }
}



.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}
.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}
.splide__pagination__page {
  background: #7b7b7b;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 3px;
  padding: 0;
  position: relative;
  transition: background-color .2s linear,transform .2s linear;
  width: 10px;
  opacity: 0.8;
}
 .splide__pagination__page:hover {
  background: #ffffff;
  cursor: pointer;
  opacity: .9;
}
 .splide__pagination__page.is-active {
  background: #ffffff;
  transform: scale(1.4);
  z-index: 1;
}